import { graphql } from '@vendure/hub/util-vendure-types'
import { serverApi } from '@vendure/shared/data-access-vendure'
import { VariablesOf } from 'gql.tada'

export const registerDoc = graphql(`
  mutation Register($input: RegisterCustomerInput!) {
    registerCustomerAccount(input: $input) {
      __typename
      ... on Success {
        success
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`)

export async function register(variables: VariablesOf<typeof registerDoc>) {
  return serverApi.request(registerDoc, variables)
}
