'use client'

import { useQueryClient } from '@tanstack/react-query'
import { KEY_ACTIVE_CUSTOMER } from '@vendure/hub/data-access'
import { useRouter } from 'next/navigation'
import { logout } from './actions'

export function SignOutButton() {
  const queryClient = useQueryClient()
  const router = useRouter()

  return (
    <button
      type="submit"
      onClick={async () => {
        await logout()
        router.replace('/sign-in')
        await queryClient.invalidateQueries({ queryKey: [KEY_ACTIVE_CUSTOMER] })
      }}
    >
      Sign Out
    </button>
  )
}
