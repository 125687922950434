import { AUTH_COOKIE_NAME } from './constants'

export async function getSessionCookie() {
  const { cookies } = await import('next/headers')
  return cookies().get(AUTH_COOKIE_NAME)
}

export async function getAuthHeaders() {
  try {
    const token = await getSessionCookie()
    if (token?.value) {
      return {
        Authorization: `Bearer ${token.value}`,
      }
    }
  } catch (error: unknown) {
    return undefined
  }
  return undefined
}

export async function deleteSessionCookie() {
  const { cookies } = await import('next/headers')
  cookies().delete(AUTH_COOKIE_NAME)
}
