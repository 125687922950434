'use client'

import { CaretDownIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import { KEY_ACTIVE_CUSTOMER } from '@vendure/hub/data-access'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vendure/shadcn/ui'
import { CustomLink } from '@vendure/shared/ui'
import { motion } from 'motion/react'
import Link from 'next/link'
import { HiUser } from 'react-icons/hi'
import { activeCustomerQuery } from './actions'
import { SignOutButton } from './sign-out-button'

export function AccountDropdown() {
  const {
    data: activeCustomer,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: [KEY_ACTIVE_CUSTOMER],
    queryFn: () => activeCustomerQuery(),
    select: (data) => data.activeCustomer,
  })

  return (
    <motion.div
      className="min-w-[100px]"
      initial={{ opacity: 0 }}
      animate={{
        opacity: isSuccess ? 1 : 0,
      }}
    >
      {activeCustomer && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
            >
              {activeCustomer?.firstName} {activeCustomer?.lastName}
              <CaretDownIcon></CaretDownIcon>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mt-1">
            <DropdownMenuItem
              asChild
              className="cursor-pointer"
            >
              <CustomLink href="/account">Account</CustomLink>
            </DropdownMenuItem>
            <DropdownMenuItem
              asChild
              className="cursor-pointer"
            >
              <CustomLink href="/account/settings">Settings</CustomLink>
            </DropdownMenuItem>
            <DropdownMenuItem className="cursor-pointer">
              <SignOutButton />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {!activeCustomer && (
        <Button
          asChild
          variant="ghost"
          className="flex items-center gap-2"
        >
          <Link href="/sign-in">
            <HiUser />
            Sign in
          </Link>
        </Button>
      )}
    </motion.div>
  )
}
